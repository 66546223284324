import React from 'react';
import { useSpring, animated } from 'react-spring';
import styled from 'styled-components';

import Box from '@/atoms/Box';

import useMeasure from '@/hooks/useMeasure';
import useScreenSize from '@/hooks/useScreenSize';

const ANIMATIONS = {
  default: undefined,
  fast: {
    tension: 300,
    clamp: true,
  },
};

const Wrapper = styled(animated.div)`
  overflow: hidden;
`;

export const CollapsibleTrigger = styled(Box)`
  ${props =>
    props.isCollapsible &&
    `
    cursor: pointer;
  `}
`;

const Collapsible = ({
  open = false,
  autoAfterExpanded = false,
  animateOpacity = true,
  animation = 'default',
  direction = 'vertical',
  fullHeight = false,
  startingHeight = 'auto',
  children,
  ...props
}) => {
  const [measureRef, { height }] = useMeasure();
  const [containerRef, { width }] = useMeasure();
  const screenSize = useScreenSize();

  let newWidth = direction === 'vertical' && screenSize.width;
  if (direction === 'horizontal') {
    newWidth = open ? width : 0;
  }

  let newHeight = direction === 'horizontal' ? screenSize.height : height;
  if (direction === 'vertical') {
    newHeight = open ? height : 0;
  }
  if (fullHeight) {
    newHeight = screenSize.height;
  }

  const spring = useSpring({
    width: newWidth,
    height: newHeight,
    maxHeight: newHeight,
    opacity: open || !animateOpacity ? 1 : 0,
    config: ANIMATIONS[animation],
    onRest: (e, s) => {
      if (e.finished === true && autoAfterExpanded && open) {
        s.set({
          maxHeight: 1000,
        });
      }
    },
  });

  return (
    <Box {...props} ref={containerRef}>
      <Wrapper style={{ maxHeight: spring.maxHeight }}>
        <animated.div style={{ opacity: spring.opacity }}>
          <div ref={measureRef}>{children}</div>
        </animated.div>
      </Wrapper>
    </Box>
  );
};

export default Collapsible;
