import { graphql } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

import Box from '@/atoms/Box';
import Image from '@/components/Image';

const BASE_WIDTHS = {
  'rect-56': 3.5,
  'rect-64': 4,
  'rect-72': 4.5,
};

// multiples of base
const SIZES = {
  tiny: 0.2,
  default: 0.5,
  large: 0.75,
  xlarge: 1,
};

const ImageWrapper = styled(Box)`
  font-size: 0;

  ${p =>
    p.iconBorder &&
    `
    border: 1px solid black;
  `}

  ${p =>
    p.iconStyle === 'circle' &&
    `
    height: ${p.w || '100%'};
    border-radius: 50%;
    overflow: hidden;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
  `}

  > div {
    ${p =>
      p.iconStyle === 'larger' &&
      `
      transform: scale(1.2);
    `}
  }
`;

const Icon = ({ size = 'default', icon, iconStyle, iconBorder, ...props }) => {
  const iconBaseWidth = BASE_WIDTHS[iconStyle] ?? BASE_WIDTHS['rect-56'];
  const width = `${iconBaseWidth * SIZES[size]}rem`;

  return (
    <ImageWrapper
      w={width}
      iconStyle={iconStyle}
      iconBorder={iconBorder}
      {...props}
    >
      <Image presentationWidth={width} {...icon} />
    </ImageWrapper>
  );
};

export default Icon;

export const query = graphql`
  fragment indexItemIconData on SanityIndexItem {
    icon {
      ... on SanityImage {
        ...imageData
      }
    }
    iconStyle
    iconBorder
  }

  fragment indexItemLargeIconData on SanityIndexItem {
    largeIcon {
      ... on SanityImage {
        ...imageData
      }
    }
    iconStyle
    iconBorder
  }
`;
