import { css } from 'styled-components';

import { media, rem } from '@/styles/utils';

const reset = css`
  font-weight: normal;
  text-decoration: none;

  a {
    color: inherit;
    text-decoration: none;
  }
`;

export const h1 = css`
  ${reset}
  font-size: ${rem(48)};
  line-height: ${rem(42)};
  font-weight: bold;
  letter-spacing: -0.04em;

  ${media.md`
    font-size: ${rem(48)};
    line-height: ${rem(42)};
  `}
`;

export const h2 = css`
  ${reset}
  font-size: ${rem(32)};
  line-height: ${rem(28)};
  font-weight: bold;
  letter-spacing: -0.02em;
`;

export const h3 = css`
  ${reset}
  font-size: ${rem(22)};
  line-height: ${rem(22)};
  font-weight: bold;
  letter-spacing: -0.02em;
`;

export const h4 = css`
  ${reset}
  font-size: ${rem(18)};
  line-height: ${rem(20)};
  font-weight: bold;
  letter-spacing: -0.02em;
`;

export const pLarge = css`
  ${reset}
  font-size: ${rem(16)};
  line-height: ${rem(18)};
  letter-spacing: -0.01em;
`;

export const p = css`
  ${reset}
  font-size: ${rem(14)};
  line-height: ${rem(16)};
  letter-spacing: -0.01em;
`;

export const pSmall = css`
  ${reset}
  font-size: ${rem(12)};
  line-height: ${rem(14)};
`;

export const pXSmall = css`
  ${reset}
  font-size: ${rem(10)};
  line-height: ${rem(12)};
`;

export const secondaryBarItem = css`
  ${reset}
  font-size: ${rem(28)};
  line-height: ${rem(28)};
  font-weight: bold;
  letter-spacing: -0.02em;

  ${media.lg`
    font-size: ${rem(16)};
    line-height: ${rem(18)};
    letter-spacing: -0.01em;
  `}
`;

export const navItem = css`
  ${reset}
  font-size: ${rem(28)};
  line-height: ${rem(28)};
  font-weight: bold;
  letter-spacing: -0.02em;

  ${media.lg`
    font-size: ${rem(16)};
    line-height: ${rem(18)};
    letter-spacing: -0.01em;
  `}
`;

export const navItemSmall = css`
  ${reset}
  font-size: ${rem(24)};
  line-height: ${rem(24)};
  font-weight: bold;
  letter-spacing: -0.02em;
`;

export const navItemLarge = css`
  ${reset}
  font-size: ${rem(28)};
  line-height: ${rem(28)};
  font-weight: bold;
  letter-spacing: -0.02em;
`;

export const batchLarge = css`
  ${reset}
  font-size: ${rem(30)};
  line-height: ${rem(32)};
  letter-spacing: 0.16em;
  text-transform: uppercase;
`;

export const batch = css`
  ${reset}
  font-size: ${rem(14)};
  line-height: ${rem(18)};
  letter-spacing: 0.16em;
  text-transform: uppercase;
`;

export const batchSmall = css`
  ${reset}
  font-size: ${rem(12)};
  line-height: ${rem(14)};
  letter-spacing: 0.16em;
  text-transform: uppercase;
`;

// double the actual size since we scale this down by 50%
export const speechbubble = css`
  ${reset}
  font-size: ${rem(20)};
  line-height: ${rem(24)};
`;
