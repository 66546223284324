import React from 'react';
import Box from '@/atoms/Box';
import NavigationItem from '@/atoms/NavigationItem';
import Link from '@/atoms/Link';

const SubItemWithChildren = ({
  item,
  closeNav,
  closeDrawer,
  index,
  openChildIndex,
  onChildClick,
}) => {
  return (
    <NavigationItem
      key={item.key}
      t="navItemLarge"
      mr={[0, 0, 0, 1, 2]}
      mb={[0.75, 0.75, 0.75, 0.15]}
    >
      <button
        onClick={() => onChildClick(openChildIndex === index ? null : index)}
      >
        {item.target.title}
      </button>
      {openChildIndex === index && (
        <Box as="ul" pl={2}>
          {item.links.map((link, key) => {
            return (
              <NavigationItem
                key={link.key}
                t="navItemLarge"
                mr={[0, 0, 0, 1, 2]}
                mb={[0.75, 0.75, 0.75, 0.15]}
              >
                <Link
                  link={link.target.link}
                  url={link.target.url}
                  targetBlank={link.target.targetBlank}
                  onClick={() => {
                    if (closeNav) closeNav();
                    if (closeDrawer) closeDrawer();
                    onChildClick(null);
                  }}
                >
                  {link.target.title}
                </Link>
              </NavigationItem>
            );
          })}
        </Box>
      )}
    </NavigationItem>
  );
};

const SubItemWithoutChildren = ({ item, closeNav, closeDrawer }) => {
  return (
    <NavigationItem
      key={item.key}
      t="navItemLarge"
      mr={[0, 0, 0, 1, 2]}
      mb={[0.75, 0.75, 0.75, 0.15]}
    >
      <Link
        link={item.target.link}
        url={item.target.url}
        targetBlank={item.target.targetBlank}
        onClick={() => {
          if (closeNav) closeNav();
          if (closeDrawer) closeDrawer();
        }}
      >
        {item.target.title}
      </Link>
    </NavigationItem>
  );
};

const ItemWithChildren = ({
  item,
  onClick,
  openParentIndex,
  index,
  closeNav,
  closeDrawer,
  openChildIndex,
  onChildClick,
}) => {
  return (
    <NavigationItem
      key={item.key}
      as="li"
      t="navItemLarge"
      mr={[0, 0, 0, 1, 2]}
      mb={[0.75, 0.75, 0.75, 0.15]}
    >
      <button onClick={() => onClick(openParentIndex === index ? null : index)}>
        {item.target.title}
      </button>
      {openParentIndex === index && (
        <Box as="ul" pl={2}>
          {item.children.map((child, key) => {
            if (child.links.length > 0) {
              return (
                <SubItemWithChildren
                  item={child}
                  closeDrawer={closeDrawer}
                  closeNav={closeNav}
                  openChildIndex={openChildIndex}
                  onChildClick={onChildClick}
                  index={key}
                />
              );
            } else {
              return (
                <SubItemWithoutChildren
                  item={child}
                  closeDrawer={closeDrawer}
                  closeNav={closeNav}
                />
              );
            }
          })}
        </Box>
      )}
    </NavigationItem>
  );
};

const ItemWithoutChildren = ({ item, closeNav, closeDrawer }) => {
  return (
    <NavigationItem
      key={item.key}
      as="li"
      t="navItemLarge"
      mr={[0, 0, 0, 1, 2]}
      mb={[0.75, 0.75, 0.75, 0.15]}
    >
      <Link
        link={item.target.link}
        url={item.target.url}
        targetBlank={item.target.targetBlank}
        onClick={() => {
          if (closeNav) closeNav();
          if (closeDrawer) closeDrawer();
        }}
      >
        {item.target.title}
      </Link>
    </NavigationItem>
  );
};

const MobileNavigation = ({ items, closeDrawer, closeNav }) => {
  const [openParentIndex, setOpenParentIndex] = React.useState(null);
  const [openChildIndex, setOpenChildIndex] = React.useState(null);

  return (
    <Box
      as="ul"
      $listStyle="none"
      $display="block"
      mb={[-0.75, -0.75, -0.75, 0]}
    >
      {items.map((item, index) => {
        if (item.children.length > 0) {
          return (
            <ItemWithChildren
              key={item.key}
              item={item}
              onClick={setOpenParentIndex}
              openParentIndex={openParentIndex}
              index={index}
              closeDrawer={closeDrawer}
              closeNav={closeNav}
              openChildIndex={openChildIndex}
              onChildClick={setOpenChildIndex}
            />
          );
        } else {
          return (
            <ItemWithoutChildren
              key={item.key}
              item={item}
              closeDrawer={closeDrawer}
              closeNav={closeNav}
            />
          );
        }
      })}
    </Box>
  );
};

export default MobileNavigation;
