import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

import Grid from '@/atoms/Grid';
import InvisibleLink from '@/atoms/InvisibleLink';
import StatusIndicator from '@/atoms/StatusIndicator';
import Text from '@/atoms/Text';

import Navigation from './Navigation';
import MobileNavigation from '../MobileNavigation';

const BatchLink = styled(InvisibleLink)`
  white-space: nowrap;
`;

const Main = ({
  activeStyle,
  drawerOpen,
  navigationItems,
  drawerItems,
  isSmallScreen,
  layout,
  location,
  factoryLocation,
  latestBatchTitle,
  latestBatchLink,
  nextBatchDate,
  closeNav,
  toggleDrawer,
  ...props
}) => {
  const detailedInfo = (
    <>
      <Grid.Item w={[1, 1, 1 / 2, 4 / 14]} $order={[4, 4, 4, 2]}>
        <Text t={-2} as="h6" mb={0.25}>
          Factory location
        </Text>
        <Text t={-1}>{factoryLocation}</Text>
      </Grid.Item>

      <Grid.Item
        w={[1 / 2, 1 / 2, 1 / 4, 2 / 14]}
        $order={[2, 2, 2, 3]}
        mb={[2.5, 2.5, 0]}
      >
        <Text t={-2} as="h6" mb={0.25}>
          Latest batch
        </Text>

        <Text t="batchSmall">
          <BatchLink to={latestBatchLink}>
            <StatusIndicator
              as="span"
              display="inline-block"
              size="small"
              variant="positive"
              blink
              mr={0.375}
            />
            {latestBatchTitle.current}
          </BatchLink>
        </Text>
      </Grid.Item>

      <Grid.Item
        w={[1 / 2, 1 / 2, 1 / 4, 2 / 14]}
        $order={[3, 3, 3, 4]}
        mb={[2.5, 2.5, 0]}
      >
        <InvisibleLink to="/#upcoming-batches">
          <Text t={-2} as="h6" mb={0.25}>
            Next batch
          </Text>
          <Text t={-1}>{moment(nextBatchDate).format('YYYY/MM/DD')}</Text>
        </InvisibleLink>
      </Grid.Item>
    </>
  );

  let navigation = isSmallScreen ? (
    <>
      <MobileNavigation
        items={navigationItems}
        closeNav={() => closeNav()}
        closeDrawer={() => closeNav()}
      />
    </>
  ) : (
    <Navigation
      activeStyle={activeStyle}
      drawerItems={drawerItems}
      drawerOpen={drawerOpen}
      toggleDrawer={i => toggleDrawer(i)}
      navigationItems={navigationItems}
      location={location}
      isSmallScreen={isSmallScreen}
      closeNav={() => closeNav()}
    />
  );

  return (
    <Grid {...props}>
      <Grid.Item w={[1, 1, 1, 6 / 14]} $order={1} mb={[4, 4, 4, 0]}>
        <Text t={-2} as="h6" mb={0.125}>
          Sitemap
        </Text>
        {navigation}
      </Grid.Item>

      {layout !== 'simple' ? detailedInfo : <></>}
    </Grid>
  );
};
export default Main;
